<template>
	<div>
		<div class="row">
			<container style="flex: 2">
				<h1 slot="title">
					Have You Checked Out Our Getting Started Guide?
				</h1>
				<div slot="body">
					<iframe
						width="710"
						height="400"
						src="https://www.youtube.com/embed/i_-E84p2QtU"
						frameborder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
			</container>
			<div style="flex: 1">
				<container v-if="hasLaunched">
					<h1 v-if="!institution.launch_date" slot="title">
						Choose Your Marketing Launch Date
					</h1>
					<h1 v-else slot="title">Days Until Launch</h1>
					<div v-if="institution.launch_date">
						<div slot="body" :style="{ paddingBottom: '0px' }">
							<h1
								:style="{
									width: '100%',
									textAlign: 'center',
									marginTop: '0px',
									marginBottom: '0px',
									fontSize: '28px'
								}"
							>
								{{ timeLeft }}
							</h1>
							<div
								:style="{
									alignItems: 'center',
									display: 'flex',
									flexDirection: 'column'
								}"
							>
								<p>Need to change your launch date?</p>
								<datepicker
									v-model="institution.launch_date"
									@selected="onUpdateLaunch"
									:inline="true"
									:use-utc="true"
								></datepicker>
							</div>
						</div>
					</div>
					<div v-else>
						<div slot="body" :style="{ paddingBottom: '0px' }">
							<div
								:style="{
									alignItems: 'center',
									display: 'flex',
									flexDirection: 'column'
								}"
							>
								<datepicker
									v-model="institution.launch_date"
									@selected="onUpdateLaunch"
									:inline="true"
									:use-utc="true"
								></datepicker>
							</div>
						</div>
					</div>
				</container>
				<div v-else>
					<container>
						<h1 slot="title">Current Snapshot</h1>
						<div slot="body">
							<div
								class="value"
								:style="{
									alignItems: 'center',
									textAlign: 'center',
									marginBottom: '15px'
								}"
							>
								<strong :style="{ fontSize: '36px' }">{{
									numberFormat(general.donatedYTD)
								}}</strong>
								<span>Processed YTD</span>
							</div>
							<div
								class="row"
								:style="{ justifyContent: 'space-around' }"
							>
								<div class="value">
									<strong>{{
										general.newDonors > 0
											? '+' + general.newDonors
											: 0
									}}</strong>
									<span>donors this week</span>
								</div>
								<div class="value">
									<strong>{{ general.totalDonors }}</strong>
									<span>total donors</span>
								</div>
							</div>
							<div
								:style="{
									marginTop: '15px',
									textAlign: 'center'
								}"
							>
								<router-link :to="{ name: 'reports' }"
									>View Detailed Reports</router-link
								>
							</div>
						</div>
					</container>
					<container style="paddingBottom: 0px">
						<h1 slot="title">Have You Tried Texting Yet?</h1>
						<div slot="body" class="row">
							<img
								src="../../../assets/TextMessage.png"
								:style="{
									flex: '1',
									maxHeight: '100px',
									maxWidth: '75px',
									marginRight: '5px',
									marginTop: '25px'
								}"
							/>
							<div
								style="flex: 4; marginTop: -25px; align-content: center; display: flex; flex-direction: column; margin-bottom: 0px; align-items: center"
							>
								<h3 style="textAlign: center">
									Texts are
									<span style="font-size:25px">17x</span> More
									Effective than Email
								</h3>
								<a
									style="marginTop: 0px"
									href="https://app.texting.roundupapp.com"
									target="_blank"
								>
									<ui-button style="marginTop: 0px"
										>Register for Connect Texting</ui-button
									>
								</a>
								<p>Get 500 free texts for signing up</p>
							</div>
						</div>
					</container>
				</div>
			</div>
		</div>
		<div class="row">
			<container style="width: 100%">
				<h1 slot="title">
					We Are Here to Help. Schedule a Call With Your Growth
					Specialist.
				</h1>
				<div slot="body" :style="{ paddingBottom: '0px' }">
					<div
						class="meetings-iframe-container"
						data-src="https://meetings.hubspot.com/jeni8/launching-with-roundup-app?embed=true"
					></div>
				</div>
			</container>
		</div>
		<div class="row">
			<container style="flex: 1" class="blog">
				<h1 slot="title">Find Success in Experience - Our Blog</h1>
				<div
					slot="body"
					v-if="!isLoading"
					:style="{ paddingBottom: '45px' }"
				>
					<blog-post v-for="(post, idx) in blog" :key="idx">
						<img
							slot="image"
							v-if="getImage(post['content:encoded']['_cdata'])"
							:src="getImage(post['content:encoded']['_cdata'])"
							:style="{ width: '500px' }"
						/>
						<h3 slot="title">{{ post.title['_text'] }}</h3>
						<p slot="description">
							<span
								v-html="getContent(post.description._cdata)"
							></span>
						</p>
					</blog-post>
				</div>
			</container>
		</div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapActions, mapState } from 'vuex'
import { numberFormat } from '../../../utilities'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import Container from '../../ui/Container'
import table from '@/mixins/table'
import UiButton from '../../ui/Button'
import BlogPost from '../../ui/BlogPost'
import axios from 'axios'
import convert from 'xml-js'
import moment from 'moment-timezone'
import MarketingDownloads from '../../layout/MarketingDownloads'

export default {
	name: 'steps-view',
	components: {
		Screen,
		UiButton,
		UiLabel,
		BlogPost,
		Datepicker,
		Container,
		table,
		MarketingDownloads
	},
	data() {
		return {
			isLoading: false,
			blog: [],
			institution: cloneDeep(this.$store.state.institution.item),
			countdown: 'Loading...',
			timerInterval: null,
			timePassed: 0
		}
	},
	computed: {
		...mapGetters([
			'institutionUuid',
			'onboardingStep',
			'hasBrand',
			'launchDate',
			'hasStripe',
			'onWebsite',
			'scheduledCall'
		]),
		...mapState({
			general: ({ reportList }) => reportList.general
		}),
		deeplink() {
			return 'https://roundup.app/p/' + this.institution.username
		},
		timeLeft() {
			var countDownDate = new Date(this.institution.launch_date).getTime()
			var now = new Date()

			var distance = countDownDate - now - this.timePassed

			var days = Math.floor(distance / (1000 * 60 * 60 * 24))
			var hours = Math.floor(
				(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			)
			var minutes = Math.floor(
				(distance % (1000 * 60 * 60)) / (1000 * 60)
			)

			return days + 'd ' + hours + 'hours ' + minutes + 'm '
		},
		hasLaunched() {
			var countDownDate = new Date(this.institution.launch_date).getTime()
			var now = new Date()

			var distance = countDownDate - now
			return distance > 0
		}
	},
	methods: {
		...mapActions(['updateInstitution', 'getGeneralReport']),
		getImage(text) {
			const start = text.search('<img src="')
			const end = text.indexOf('"', start + 10)
			var figureUrl = text.substring(start + 10, end)
			if (figureUrl.indexOf('http') > -1) {
				return figureUrl
			}
			return null
		},
		getContent(text) {
			const end = text.search('Continued</a></p>')
			return text.substring(0, end) + 'Continued</a></p>'
		},
		download(row) {
			window.open(row.link, '_blank')
		},
		startTimer() {
			this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
		},
		onUpdateLaunch() {
			this.institution.launch_date = moment(this.institution.launch_date)
				.tz('UTC')
				.format('YYYY-MM-DD')
			this.updateInstitution(this.institution)
		},
		numberFormat
	},
	mounted() {
		let hsScript = document.createElement('script')
		hsScript.setAttribute(
			'src',
			'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
		)
		document.head.appendChild(hsScript)

		this.isLoading = true
		axios
			.get(
				'https://cors-anywhere.herokuapp.com/https://roundupapp.com/feed'
			)
			.then(res => {
				const json = JSON.parse(
					convert.xml2json(res.data, { compact: true, spaces: 4 })
				)
				this.blog = json['rss']['channel']['item']
				this.isLoading = false
			})
			.catch(() => {
				this.isLoading = false
				this.error =
					'Uh Oh! We had a problem loading our blog. Check it out here: https://roundupapp.com/blog'
			})
		this.startTimer()
		this.getGeneralReport()
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';
.row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	max-width: 100%;
}

.blog {
	max-height: 750px;
	overflow-y: scroll;
	position: relative;
}
</style>
