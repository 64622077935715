import { pluck } from '../utilities'
export default {
	props: {
		page: {
			type: Number,
			default: 1
		},
		perPage: {
			type: Number,
			default: 5
		},
		sortBy: String,
		sortDir: {
			type: String,
			default: 'asc'
		}
	},
	data() {
		return {
			tableParamDefaultKeys: ['page', 'perPage', 'sortBy', 'sortDir'],
			tableParamKeys: []
		}
	},
	computed: {
		tableMergedKeys() {
			return [...this.tableParamDefaultKeys, ...this.tableParamKeys]
		}
	},
	methods: {
		pageChange(page) {
			let params = this.getParameters()
			params.page = page
			this.setParameters(params)
		},
		sortChange(sort) {
			let params = this.getParameters()
			params.sortBy = sort.prop
			params.sortDir = sort.order === 'descending' ? 'desc' : 'asc'
			this.setParameters(params)
		},
		setParameters(query) {
			this.$router.replace({ query: query })
			document.body.scrollTop = document.documentElement.scrollTop = 0
			this.paramsChanged(query)
		},
		paramsChanged(params) {},
		getParameters() {
			return pluck(this, this.tableMergedKeys)
		}
	}
}
