var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('container',{staticStyle:{"flex":"2"}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Have You Checked Out Our Getting Started Guide? ")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('iframe',{attrs:{"width":"710","height":"400","src":"https://www.youtube.com/embed/i_-E84p2QtU","frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])]),_c('div',{staticStyle:{"flex":"1"}},[(_vm.hasLaunched)?_c('container',[(!_vm.institution.launch_date)?_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Choose Your Marketing Launch Date ")]):_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Days Until Launch")]),(_vm.institution.launch_date)?_c('div',[_c('div',{style:({ paddingBottom: '0px' }),attrs:{"slot":"body"},slot:"body"},[_c('h1',{style:({
								width: '100%',
								textAlign: 'center',
								marginTop: '0px',
								marginBottom: '0px',
								fontSize: '28px'
							})},[_vm._v(" "+_vm._s(_vm.timeLeft)+" ")]),_c('div',{style:({
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column'
							})},[_c('p',[_vm._v("Need to change your launch date?")]),_c('datepicker',{attrs:{"inline":true,"use-utc":true},on:{"selected":_vm.onUpdateLaunch},model:{value:(_vm.institution.launch_date),callback:function ($$v) {_vm.$set(_vm.institution, "launch_date", $$v)},expression:"institution.launch_date"}})],1)])]):_c('div',[_c('div',{style:({ paddingBottom: '0px' }),attrs:{"slot":"body"},slot:"body"},[_c('div',{style:({
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column'
							})},[_c('datepicker',{attrs:{"inline":true,"use-utc":true},on:{"selected":_vm.onUpdateLaunch},model:{value:(_vm.institution.launch_date),callback:function ($$v) {_vm.$set(_vm.institution, "launch_date", $$v)},expression:"institution.launch_date"}})],1)])])]):_c('div',[_c('container',[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Current Snapshot")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"value",style:({
								alignItems: 'center',
								textAlign: 'center',
								marginBottom: '15px'
							})},[_c('strong',{style:({ fontSize: '36px' })},[_vm._v(_vm._s(_vm.numberFormat(_vm.general.donatedYTD)))]),_c('span',[_vm._v("Processed YTD")])]),_c('div',{staticClass:"row",style:({ justifyContent: 'space-around' })},[_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.general.newDonors > 0 ? '+' + _vm.general.newDonors : 0))]),_c('span',[_vm._v("donors this week")])]),_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.general.totalDonors))]),_c('span',[_vm._v("total donors")])])]),_c('div',{style:({
								marginTop: '15px',
								textAlign: 'center'
							})},[_c('router-link',{attrs:{"to":{ name: 'reports' }}},[_vm._v("View Detailed Reports")])],1)])]),_c('container',{staticStyle:{"paddingBottom":"0px"}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Have You Tried Texting Yet?")]),_c('div',{staticClass:"row",attrs:{"slot":"body"},slot:"body"},[_c('img',{style:({
								flex: '1',
								maxHeight: '100px',
								maxWidth: '75px',
								marginRight: '5px',
								marginTop: '25px'
							}),attrs:{"src":require("../../../assets/TextMessage.png")}}),_c('div',{staticStyle:{"flex":"4","marginTop":"-25px","align-content":"center","display":"flex","flex-direction":"column","margin-bottom":"0px","align-items":"center"}},[_c('h3',{staticStyle:{"textAlign":"center"}},[_vm._v(" Texts are "),_c('span',{staticStyle:{"font-size":"25px"}},[_vm._v("17x")]),_vm._v(" More Effective than Email ")]),_c('a',{staticStyle:{"marginTop":"0px"},attrs:{"href":"https://app.texting.roundupapp.com","target":"_blank"}},[_c('ui-button',{staticStyle:{"marginTop":"0px"}},[_vm._v("Register for Connect Texting")])],1),_c('p',[_vm._v("Get 500 free texts for signing up")])])])])],1)],1)],1),_c('div',{staticClass:"row"},[_c('container',{staticStyle:{"width":"100%"}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" We Are Here to Help. Schedule a Call With Your Growth Specialist. ")]),_c('div',{style:({ paddingBottom: '0px' }),attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"meetings-iframe-container",attrs:{"data-src":"https://meetings.hubspot.com/jeni8/launching-with-roundup-app?embed=true"}})])])],1),_c('div',{staticClass:"row"},[_c('container',{staticClass:"blog",staticStyle:{"flex":"1"}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Find Success in Experience - Our Blog")]),(!_vm.isLoading)?_c('div',{style:({ paddingBottom: '45px' }),attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.blog),function(post,idx){return _c('blog-post',{key:idx},[(_vm.getImage(post['content:encoded']['_cdata']))?_c('img',{style:({ width: '500px' }),attrs:{"slot":"image","src":_vm.getImage(post['content:encoded']['_cdata'])},slot:"image"}):_vm._e(),_c('h3',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(post.title['_text']))]),_c('p',{attrs:{"slot":"description"},slot:"description"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getContent(post.description._cdata))}})])])}),1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }