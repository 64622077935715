<template>
	<section class="blog-post">
		<slot name="image" :style="{ width: '500px' }"></slot>
		<slot name="title"></slot>
		<slot name="description"></slot>
		<slot></slot>
	</section>
</template>

<script>
export default {
	name: 'roundup-container',
	props: [],
	computed: {}
}
</script>

<style lang="scss">
@import '../../styles/variables';
.blog-post {
	border-bottom: 1px solid $roundup-light-grey;
	margin-bottom: 20px;
}
</style>
