<template>
	<section class="container">
		<div
			:class="full ? 'full' : ''"
			class="container-header"
			v-if="hasTitleSlot"
		>
			<div class="container-title">
				<slot name="title"></slot>
				<slot name="subtitle"></slot>
			</div>
			<div class="container-buttons">
				<slot name="buttons"></slot>
			</div>
		</div>
		<div class="container-chart" v-if="hasChartSlot">
			<slot name="chart"></slot>
		</div>
		<div class="container-table" v-if="hasTableSlot">
			<slot name="table"></slot>
		</div>
		<div class="container-body" v-if="hasBodySlot">
			<slot name="body"></slot>
		</div>
		<div class="container-footer">
			<slot name="footer"></slot>
		</div>
		<slot></slot>
	</section>
</template>

<script>
export default {
	name: 'roundup-container',
	props: ['size', 'full'],
	computed: {
		hasTitleSlot() {
			return !!this.$slots['title']
		},
		hasTableSlot() {
			return !!this.$slots['table']
		},
		hasChartSlot() {
			return !!this.$slots['chart']
		},
		hasBodySlot() {
			return !!this.$slots['body']
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';

.container {
	background: $white;
	padding: 20px;
	border-radius: 14px;
	overflow: hidden;
	overflow-x: hidden;
	max-width: none !important;
	&.small {
		max-width: 420px;
	}
	&.medium {
		max-width: 600px;
	}
	&.large {
		max-width: none !important;
		min-width: 1024px;
	}
	.container-header {
		display: flex;
		margin: -20px -20px -20px -20px;
		padding: 15px 20px 0px 50px;
		.container-title {
			flex-grow: 1;
			h1 {
				font-size: 24px;
				font-weight: 600;
				margin-top: 5px;
				margin-bottom: 0px;
				color: $roundup-charcoal;
			}
		}
		.container-buttons {
			flex-grow: 0;
			flex-shrink: 0;
			button {
				margin-top: 0px;
			}
		}
	}
	.full {
		padding: 0px;
	}
	.container-body {
		padding: 0px 20px;
		max-height: 100%;
		padding-bottom: 5px;
	}
	.container-table {
		position: relative;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0px 40px;
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 48px;
			top: -1px;
			left: 0px;
			background: $roundup-lightest-grey;
			border-top: 1px solid $roundup-light-grey;
			border-bottom: 1px solid $roundup-lighter-grey;
		}
	}
	.el-table__header-wrapper {
		position: relative;
	}
	.el-table--enable-row-hover {
		.el-table__body {
			tr {
				td {
					transition: none;
					cursor: pointer;
				}
				&:hover {
					border-radius: 5px;
					overflow: hidden;
					td {
						&:first-child {
							border-top-left-radius: 10px;
							border-bottom-left-radius: 10px;
						}
						&:last-child {
							border-top-right-radius: 10px;
							border-bottom-right-radius: 10px;
						}
					}
				}
			}
		}
	}
	.container-chart {
		background: $roundup-lightest-grey;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0px 20px;
		border-bottom: 1px solid $roundup-light-grey;
		min-height: 300px;
		.chart-options {
			padding: 10px 20px 0px;
		}
	}
	.container-footer {
		margin-top: 20px;
		text-align: center;
	}
}
.container .el-table__header-wrapper tr,
.container .el-table__header-wrapper th {
	background: $roundup-lightest-grey;
	text-transform: uppercase;
}
.container .el-table__header-wrapper tr .cell,
.container .el-table__header-wrapper th .cell {
	font-size: 12px;
}
</style>
